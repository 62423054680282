var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"title":_vm.$t('myMail.pleaseselectyournameafterassignment'),"visible":_vm.dialogVisible,"width":_vm.width,"top":"0","custom-class":"dialog","close-on-click-modal":false},on:{"update:visible":function($event){_vm.dialogVisible=$event},"close":_vm.close},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('el-row',{staticClass:"text-center"},[_c('el-button',{on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('mailConfig.Cancel'))+" ")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('mailConfig.Confirm'))+" ")])],1)]},proxy:true}])},[_c('div',{staticClass:"dialog-content"},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":"0","label-position":_vm.lang === 'zh' ? 'left' : 'top'}},[_c('el-row',{attrs:{"gutter":24}},[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"prop":"userId","label":"","rules":[
              {
                required: true,
                trigger: 'change',
                message: _vm.$t('placeholder.plsSel'),
              } ]}},[_c('el-select',{attrs:{"clearable":"","placeholder":_vm.$t('myMail.dissupzhen'),"filterable":""},on:{"change":_vm.checkUserOnline},model:{value:(_vm.form.userId),callback:function ($$v) {_vm.$set(_vm.form, "userId", $$v)},expression:"form.userId"}},_vm._l((_vm.dispatchUsers),function(user,index){return _c('el-option',{key:user.userId,attrs:{"label":_vm.lang !== 'en'
                    ? ((user.userNameCn) + "(" + (user.userNameEn) + ")")
                    : user.userNameEn,"value":user.userId}})}),1)],1),(_vm.userOfflineError)?_c('p',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm.userOfflineError)+" ")]):_vm._e()],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
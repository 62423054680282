<template>
  <el-dialog
    :title="$t('myMail.pleaseselectyournameafterassignment')"
    :visible.sync="dialogVisible"
    :width="width"
    top="0"
    custom-class="dialog"
    :close-on-click-modal="false"
    @close="close"
  >
    <div class="dialog-content">
      <el-form
        ref="form"
        :model="form"
        label-width="0"
        :label-position="lang === 'zh' ? 'left' : 'top'"
      >
        <el-row :gutter="24">
          <el-col :span="24">
            <!-- 分派表单 -->
            <el-form-item
              prop="userId"
              label=""
              :rules="[
                {
                  required: true,
                  trigger: 'change',
                  message: $t('placeholder.plsSel'),
                },
              ]"
            >
              <el-select
                v-model="form.userId"
                clearable
                :placeholder="$t('myMail.dissupzhen')"
                filterable
                @change="checkUserOnline"
              >
                <el-option
                  v-for="(user, index) in dispatchUsers"
                  :key="user.userId"
                  :label="
                    lang !== 'en'
                      ? `${user.userNameCn}(${user.userNameEn})`
                      : user.userNameEn
                  "
                  :value="user.userId"
                />
              </el-select>
            </el-form-item>
            <!-- 用户不在线错误提示 -->
            <p v-if="userOfflineError" class="error-message">
              {{ userOfflineError }}
            </p>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <template #footer>
      <el-row class="text-center">
        <el-button @click="close">
          <!-- 取 消 -->
          {{ $t('mailConfig.Cancel') }}
        </el-button>

        <el-button type="primary" @click="save" :loading="loading">
          <!-- 确 认 -->
          {{ $t('mailConfig.Confirm') }}
        </el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { MailInteractor } from '@/core'
  import { BusEmit } from '@/utils/eventBus'

  export default {
    name: 'Dispatch',
    props: {
      width: {
        type: String,
        default: '504px',
      },
    },
    data() {
      return {
        dialogVisible: false,
        form: {
          userId: '',
        },
        dispatchUsers: [], //分派人员列表
        loading: false,
        userOfflineError: '', // 添加用户不在线错误信息
      }
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
    },

    methods: {
      showAddEdit(mailList, isSingle) {
        this.isSingle = isSingle
        this.mailId = mailList.map((mail) => mail.mailId)
        this.dialogVisible = true
        this.getDispatchUsers(mailList)
      },

      // 检查用户是否在线
      async checkUserOnline(userId) {
        if (!userId) {
          this.userOfflineError = ''
          return
        }
        try {
          // 调用接口检查用户是否在线
          // 通过MailInteractor.checkUserOnlineApi接口检查用户在线状态
          // 参数：userId - 用户ID
          // 返回：res.data为true表示用户在线，false表示用户不在线
          const res = await MailInteractor.checkUserOnlineApi(userId)
          if (res?.code === '000000' && !res?.data?.[0]?.online) {
            // 用户不在线，显示提示信息
            this.userOfflineError = this.$t('myMail.userOffline')
          } else {
            // 用户在线或接口返回异常，清除错误提示
            this.userOfflineError = ''
          }
        } catch (error) {
          console.error('检查用户在线状态失败', error)
          // 发生异常时清除错误提示，避免阻止用户操作
          this.userOfflineError = ''
        }
      },

      // 重置表单

      close() {
        this.form.userId = ''
        this.mailId = ''
        this.userOfflineError = '' // 清除错误信息
        this.$nextTick(() => {
          this.$refs.form.clearValidate('userId') //移除表单校验，一般用于修改
          this.dialogVisible = false
        })
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            this.loading = true
            let reqMethod = this.isSingle
              ? MailInteractor.singleDispatchApi
              : MailInteractor.dispatchApi
            reqMethod({
              mailIds: this.mailId,
              userId: this.form.userId,
            })
              .then((res) => {
                if (res?.code === '000000') {
                  this.$message.success(
                    this.$t('myMail.dispatchedsuccessfully')
                  )
                  this.close()
                  // 刷新左侧账号列表数据
                  BusEmit('MailLeftAside', 'refreshData')
                  // 刷新邮箱列表数据
                  BusEmit('MailList', 'getPageList')
                }
              })
              .then(() => {
                this.loading = false
              })
          }
        })
      },

      // 获取分派人员账号数据
      async getDispatchUsers(mailList) {
        let emailAccount = mailList && mailList[0]?.emailAccount
        let res = await MailInteractor.listDispatchUserApi(emailAccount)

        if (res?.code === '000000') {
          this.dispatchUsers = res.data
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  ::v-deep .dialog {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .dialog-content {
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: 20px;
    min-height: 92px;

    .el-form {
      width: 90%;
      margin: 0 auto;
    }

    ::v-deep {
      .el-select {
        width: 100%;
      }
    }

    .error-message {
      color: #ff4d4f;
      font-size: 12px;
      margin-top: -14px;
    }
  }
</style>
